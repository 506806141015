// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react";

export const _021820250600031120250600Context = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export function use_021820250600031120250600() {
  return React.useContext(_021820250600031120250600Context);
}

export default _021820250600031120250600Context;
/* prettier-ignore-end */
