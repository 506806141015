// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react";

export const _022020251400031420252300Context = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export function use_022020251400031420252300() {
  return React.useContext(_022020251400031420252300Context);
}

export default _022020251400031420252300Context;
/* prettier-ignore-end */
